<template>
    <div>
        <pdf
                ref="pdf"
                v-for="i in numPages"
                :key="i"
                :src="certUrl"
                :page="i"
        ></pdf>
    </div>
</template>
<script>
    import pdf from 'vue-pdf'
    import {baseURL} from '@/utils/request';

    export default {
        name: 'Pdf',
        components: {
            pdf
        },
        data() {
            return {
                certUrl:'',
                numPages: null, // pdf 总页数
            }
        },
        created(){
            let url = this.$route.params.certUrl;
            this.certUrl=baseURL+url.slice(1, url.length);
        },
        mounted() {
            this.getNumPages();
        },
        methods:{
            //计算pdf页码总数
            getNumPages() {
                let loadingTask = pdf.createLoadingTask(this.certUrl)
                loadingTask.promise.then(pdf => {
                    this.numPages = pdf.numPages
                }).catch(err => {
                    console.error('pdf 加载失败', err);
                })
            },
        }
    }
</script>
